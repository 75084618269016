<template>
		<v-app id="main-app">
		<v-container class="fill-height" fluid>
			<v-card class="mx-auto px-10 pb-9 login-card" width="500px">
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<!-- <v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px !important;font-weight: bold;">{{$t('successfully-activation')}}</v-card-subtitle> -->
			<template>
				<v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px !important;font-weight: bold;">
					{{message}}
				</v-card-subtitle>
			</template>
			<v-card-subtitle class="text-center title-login primary--text pt-0" style="font-size: 16px !important"></v-card-subtitle>
			<alert-list-auth />
			<v-card-text class="text-center">
			</v-card-text>
			<v-footer color="transparent" class="px-10">
				<v-row dense>
				<v-col class="text-center black--text ma-0 pa-0" cols="12">
					<span style="font-size: 11px; color: grey">{{$t('signing-in-to-this-webapp')}}</span>
					<span style="font-size: 11px; cursor: pointer;" class="primary--text" @click="redirectToWebsiteTerms"> {{$t('website-terms-of-use')}} </span>
				</v-col>
				</v-row>
				<v-row dense class="ma-0 pa-0">
				<v-col class="text-center black--text mt-0 pt-0" cols="12">
					<span style="font-size: 11px; color: grey"> {{$t('acknowledge')}}</span>
					<span class="primary--text" style="font-size: 11px; color: grey; cursor: pointer;" @click="redirectToPrivacyTerms"> {{$t('privacy-policy')}}</span>
				</v-col>
				</v-row>
			</v-footer>
			</v-card>
		</v-container>
		</v-app>
  </template>

<script>
// import _axios from '@/plugins/axios';
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
// import CountryFlag from 'vue-country-flag';
import { iosQrCode, androidQrCode, spanishAppStore, spanishPlayStore, englishAppStore, englishPlayStore } from '@/assets';
const { LOGIN_LOGO } = require('@/themes/VitalCheckups/image-paths');

export default {
	components: {
		AlertListAuth,
		// CountryFlag,
	},
	data () {
		return {
			iosQrCode,
			englishPlayStore,
			englishAppStore,
			spanishPlayStore,
			spanishAppStore,
			androidQrCode,
			logo: LOGIN_LOGO,
			showForm: 1,
			showPassword: false,
			logo_bridge: logoBridge,
			lang: 'en',
			email: '',
			password: '',
			emailSent: false,
			result: null,
			message: null,
		};
	},
	async mounted () {
		// this.language(this.$route.query.language);
		// if (this.$store.getters['authentication/hasStoredSession']) {
		// 	this.$store.dispatch('authentication/doLogout');
		// 	this.$store.commit('alerts/clear');
		// 	localStorage.clear();
		// }

		// const response = await _axios.get(this.$route.fullPath.split('api')[1]);
		// this.language(this.$route.query.language);
		// this.isPatient = this.$route.query.isPatient;
		// this.isCaregiver = this.$route.query.isCaregiver;
		this.result = this.$route.query.result;
		// if (response.status === 200) {
		// 	if (!response.data.resFlag) {
		// 		this.message = this.$t('profile-successfully-removed');
		// 		this.apiLoaded = false;
		// 	} else {
		// 		this.apiLoaded = true;
		// 		this.message = this.$t('profile-fail-to-removed');
		// 	}
		// }

		if (this.result === 'SUCCESS') {
			this.message = this.$t('profile-successfully-removed');
		} else {
			this.message = this.$t('profile-fail-to-removed');
		}
	},
	methods: {
		language (val) {
			if (val === null || val === undefined) {
				this.lang = 'en';
				this.$i18n.locale = 'en';
			} else {
				if (val === 'ENG') {
					this.lang = 'en';
					this.$i18n.locale = 'en';
				} else if (val === 'ESP') {
					this.lang = 'es';
					this.$i18n.locale = 'es';
				} else {
					this.lang = 'en';
					this.$i18n.locale = 'en';
				}
			}
		},
		redirectToWebsiteTerms () {
			window.open('https://www.vitalcheckups.com/vitakcheckups_website_terms_of_use_rw_4_28_2022.html', '_blank');
		},
		redirectToPrivacyTerms () {
			window.open('https://www.vitalcheckups.com/vitalcheckups_website_privacy_policy_rw__4_28_2022.html', '_blank');
		},
	},
};
</script>
<style scoped>
#main-app {
  background: linear-gradient(#E8E8E8, #d6d6d6);
}
</style>
